import DriversScreen from "../screens/drivers/pages/drivers_screen";
import UsersScreen from "../screens/users/pages/users_screen";
import UserRideScreen from "../screens/rides/pages/user_ride_screen";
import DriverRideDetailsScreen from "../screens/rides/pages/driver_ride_details_screen";
import PassengerRideDetailsScreen from "../screens/rides/pages/passenger_ride_details_screen";
import dashboard from "../screens/dashboard/pages/dashboard";
import dashboardDuplicate from "../screens/dashboard/pages/dashboard_screen";
import AllRidesScreen from "../screens/rides/pages/all_rides_screen";
import DocumentsVerificationsScreen from "../screens/drivers/pages/documents_verifications_screen";
import CorporateCodeScreen from "../screens/corporate_codes/pages/corporate_codes_screen";
import AvtiveSupportRequestsScreen from "../screens/Support/components/Avtive_Support";
import ResolvedSupportRequestsScreen from "../screens/Support/components/Resolved_Support";
import Support_chat from "../screens/Support/pages/Support_chat";
import SubjectScreen from "../screens/Support/pages/subject/Subject";
import UserRatingScreen from "../screens/rides/pages/user_rating_screen";
import UserVehiclesListScreen from "../screens/drivers/components/vehicles_user";
import BlockedUsers from "../screens/users/pages/blocked_users_screen";
import AllUsersScreen from "../screens/users/pages/all_users_screen";
import DriverRatingScreen from "../screens/rides/pages/driver_rating_screen";
import OwnerRideScreen from "../screens/rides/pages/owner_rides_screen";
import AdminUsersScreen from "../screens/users/pages/admin_users_screen";
import Profile from "../screens/profile/Profile";

// import AdsScreen from "../screens/ads/pages/ads_screen";
//import { icon } from '@fortawesome/fontawesome-svg-core';
// import AdminUsersScreen from "../screens/users/pages/admin_users_screen";
// import FareManagementScreen from "../screens/fare/pages/fare_management_screen";
// import VersionControlScreen from "../screens/versions/pages/version_control_screen";
// import FaqsScreen from "../screens/faqs/pages/faqs_screen";
// import appUrl from "./constants/appUrl";
// import dotenv from "dotenv";
// import { vehicleType } from "../screens/vehiclemanagement/pages/vehicle-type";
// import { vehicleModel } from "../screens/vehiclemanagement/pages/vehicle-model";
// import { vehicleMakers } from "../screens/vehiclemanagement/pages/vehicle-makers";
// import VehiclesScreen from "../screens/verification/pages/vehicles_screen";
// import PersonalScreen from "../screens/verification/pages/personal_screen";
// import TutorailScreen from "../screens/tutorials/pages/tutorail_screen";

// import PaidAccountsScreen from "../screens/accounts/pages/paid_accounts_screen";
// import UnPaidAccountsScreen from "../screens/accounts/pages/unpaid_account_screen";
// import InprogressScreen from "../screens/accounts/pages/inprogress_account_screen";
// import Roles from "../screens/rolesmanagement/pages/roles";
// import Module from "../screens/rolesmanagement/pages/module";
// import ReferrerScreen from "../screens/referrer/pages/referrer_screen";
// import SmsLogs from "../screens/smsLogs/pages/sms_logs_screen";
const routes = [
    { path: "/", component: dashboard },
    { path: "/dashboard", component: dashboardDuplicate },
    { path: "/api/user/drivers", component: DriversScreen },
    { path: "/api/user/drivers/ratings/list/:userId/:type", component: DriverRatingScreen },
    { path: "/api/user/drivers/rides/list/:userId/:type", component: OwnerRideScreen },
    { path: "/api/user/drivers/vehicles/list/:userId/:type", component: UserVehiclesListScreen },
    { path: "/api/user/drivers/ride/:id", component: DriverRideDetailsScreen },
    { path: "/api/user/passengers", component: UsersScreen },
    { path: "/api/user/passengers/ratings/list/:userId/:type", component: UserRatingScreen },
    { path: "/api/user/passengers/rides/list/:userId/:type", component: UserRideScreen },
    { path: "/api/user/passengers/ride/:id", component: PassengerRideDetailsScreen },
    { path: "/api/rides/active", component: AllRidesScreen, props: { flag: "active" } },
    { path: "/api/rides/upcoming", component: AllRidesScreen, props: { flag: "upcoming" } },
    { path: "/api/rides/history", component: AllRidesScreen, props: { flag: "history" } },
    { path: "/api/rides/active/driver/:id", component: DriverRideDetailsScreen, props: { flag: "active" } },
    { path: "/api/rides/upcoming/driver/:id", component: DriverRideDetailsScreen, props: { flag: "upcoming" } },
    { path: "/api/rides/history/driver/:id", component: DriverRideDetailsScreen, props: { flag: "history" } },
    { path: "/api/rides/active/passenger/:id", component: PassengerRideDetailsScreen, props: { flag: "active" } },
    { path: "/api/rides/upcoming/passenger/:id", component: PassengerRideDetailsScreen, props: { flag: "upcoming" } },
    { path: "/api/rides/history/passenger/:id", component: PassengerRideDetailsScreen, props: { flag: "history" } },
    { path: "/api/documents/verifications", component: DocumentsVerificationsScreen },
    { path: "/api/corporate/list", component: CorporateCodeScreen },
    { path: "/api/support/active/", component: AvtiveSupportRequestsScreen, props: { status: "active" } },
    { path: "/api/support/resolved/", component: ResolvedSupportRequestsScreen, props: { status: "resolved" } },
    { path: "/api/support/active/chat/:id", component: Support_chat },
    { path: "/api/support/resolved/chat/:id", component: Support_chat },
    { path: "/api/support/subject", component: SubjectScreen },
    { path: "/api/admin/users", component: AdminUsersScreen },
    // { path: "/api/fare", component: FareManagementScreen },
    // { path: "/api/ads", component: AdsScreen },
    // { path: "/api/versions", component: VersionControlScreen },
    // { path: "/api/faqs", component: FaqsScreen },
    // { path: "/api/tutorial", component: TutorailScreen },
    // { path: "/api/paidaccounts", component: PaidAccountsScreen },
    // { path: "/api/unpaidaccounts", component: UnPaidAccountsScreen },
    // { path: "/api/inprogressaccounts", component: InprogressScreen },
    // { path: "/api/roles", component: Roles },
    // { path: "/api/module", component: Module },
    // { path: "/api/logs", component: SmsLogs },
    // { path: "/api/referrer", component: ReferrerScreen },
    // { path: "/api/verification/vehicles", component: VehiclesScreen },
    // { path: "/api/verification/personal", component: PersonalScreen },
    // { path: "/api/vehicleManagement/vehicleModels", component: vehicleModel },
    // { path: "/api/vehicleManagement/vehicleType", component: vehicleType },
    // { path: "/api/vehicleManagement/vehicleMakers", component: vehicleMakers },
    { path: "/api/alluserscreen", component: AllUsersScreen },
    { path: "/api/blockeduserlist", component: BlockedUsers },
];

export default routes;
