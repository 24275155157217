import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "./redux/slices/admin_user_slice";
import appUrl from "./app/features/constants/appUrl";
import { Image } from "primereact/image";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
//import {  LOGOUT_USER } from './redux/constants/admin_user_constants';
import { useHistory } from "react-router-dom";

export const AppTopbar = (props) => {
    const [corporateName, setCorporateName] = useState("");
    const history = useHistory();
    let user = localStorage.getItem("user");

    if (user) {
        user = JSON.parse(user);
    }

    const dispatch = useDispatch();
    const accept = () => {
        //dispatch({type:LOGOUT_USER})
        dispatch(logoutUser());
    };

    const reject = () => {};

    const signoutConfirm = () => {
        confirmDialog({
            message: "Do you want to logout?",
            header: "Confirmation",
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-success p-button-rounded p-button-sm custom___width",
            accept,
            reject,
        });
    };

    const menuRight = useRef(null);
    const items = [
        {
            label: "Logout",
            items: [
                {
                    label: "Profile",
                    icon: "pi pi-user",
                    command: () => {
                        history.push("/api/profile");
                    },
                },
                {
                    label: "Sign out",
                    icon: "pi pi-sign-out",
                    command: () => {
                        signoutConfirm();
                    },
                },
            ],
        },
    ];

    const userData = localStorage.getItem("user");
    const data = JSON.parse(userData);
    const corporateImage = `${appUrl?.baseUrl}${data?.corporate?.logo}`;

    return (
        <div className="layout-topbar">
            <ConfirmDialog />

            <Link to="/" className="layout-topbar-logo">
                <img src={"app/logo.png"} alt="logo" />
                {/* <span>Rahper</span> */}
            </Link>

            <button type="button" className="p-link  layout-menu-button layout-topbar-button" onClick={props.onToggleMenuClick}>
                <i className="pi pi-bars" />
            </button>

            <button type="button" className="p-link layout-topbar-menu-button layout-topbar-button" onClick={props.onMobileTopbarMenuClick}>
                <i className="pi pi-ellipsis-v" />
            </button>
            <p className="mt-3 ml-3" style={{ fontWeight: "600", color: "#5052e2" }}>
                {user?.corporate?.name}
            </p>
            <ul className={classNames("layout-topbar-menu lg:flex origin-top", { "layout-topbar-menu-mobile-active": props.mobileTopbarMenuActive })}>
                <li style={{ display: "flex", alignItems: "center" }}>
                    <Link to="/api/profile">
                        <Image imageStyle={{ borderColor: "red", borderRadius: "50%", width: "30px", height: "30px" }} src={user?.profileImage ? appUrl.baseUrl + user.profileImage : appUrl.baseUrl + "images/defaultprofile.jpg"} />
                    </Link>
                </li>
                {/* <li>
                    <button
                        className="p-link layout-topbar-button"
                        onClick={() => {
                            signoutConfirm();
                        }}
                    >
                        <i className="pi pi-power-off" />
                        <span>Logout</span>
                    </button>
                </li> */}
            </ul>
            <div className="pms-topbar">
                <div className="flex align-items-center">
                    <Menu model={items} popup ref={menuRight} id="popup_menu_right" popupAlignment="right" />
                    <button className="p-link layout-topbar-button mx-4" onClick={(e) => menuRight.current.toggle(e)}>
                        <i className="pi pi-power-off"></i>
                    </button>
                </div>
            </div>
        </div>
    );
};
