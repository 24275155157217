import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCorporateCodesList } from "../../../../../redux/slices/corporate_code_slice";
import AddCorporateCodeDialog from "../componenets/add_corporate_code_dialog";
import { InputText } from "primereact/inputtext";
import CustomPageHeader from "../../../components/custom_page_header";
import { Paginator } from "primereact/paginator";

export default function CorporateCodeScreen({ permissions }) {
    const dispatch = useDispatch();
    //hooks
    const [editCode, setEditCode] = useState(null);
    const [pageNumber, setPageNumber] = useState(0);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(50);
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [editable, setEditable] = useState(false);

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;

        setGlobalFilterValue(value);
    };

    const userInfo = localStorage.getItem("user");
    const parsedData = JSON.parse(userInfo);
    const corporateId = parsedData?.corporate?._id;

    console.log("corporate id", parsedData);

    const { data: list, loading, count } = useSelector((state) => state.corporateCode);
    useEffect(() => {
        if (list === undefined) {
            dispatch(getCorporateCodesList({ pageNumber, text: globalFilterValue, perPageRecords: rows, corporate: corporateId }));
        }
    }, []);
    useEffect(() => {
        setPageNumber(0);
        setFirst(0);
        dispatch(getCorporateCodesList({ pageNumber, text: globalFilterValue, perPageRecords: rows, corporate: corporateId }));
    }, [globalFilterValue]);
    const [showAddDialog, setAddDialog] = useState(false);

    const dialogFuncMap = {
        showAddDialog: setAddDialog,
    };
    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
    };

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    };
    const feeBodyTemplate = (rowData) => {
        return (
            <div className="flex justify-content-left">
                <span className={rowData.fee > 0 ? "green font-bold ml-3" : "red font-bold ml-3"}>{rowData.fee > 0 ? rowData.fee : rowData.fee * -1}</span>
            </div>
        );
    };
    const statusBodyTemplate = (rowData) => {
        return <div className={rowData.active ? "bg_green" : "bg_red"}>{rowData.active ? "Active" : "Inactive"}</div>;
    };
    const actionTemplate = (rowData) => {
        return (
            <div className="Edit_Icon">
                {permissions?.view && (
                    <Button
                        tooltip="View Details"
                        icon="pi pi-pencil"
                        tooltipOptions={{ position: "top" }}
                        onClick={() => {
                            setEditCode(rowData);
                            setEditable(true);
                            onClick("showAddDialog");
                        }}
                        className="edit mr-2 p-button-success p-button-rounded"
                    />
                )}
            </div>
        );
    };

    const onPageChange = (event) => {
        setFirst(event.first);
        setPageNumber(event.first / event.rows);
        setRows(event.rows);
        dispatch(getCorporateCodesList({ pageNumber: event.first / event.rows, text: globalFilterValue, perPageRecords: event.rows, corporate: corporateId }));
    };

    return (
        <>
            <div className="card">
                <Dialog header={editCode == null ? "Add Corporate Code" : "Update Corporate Code"} visible={showAddDialog} onHide={() => onHide("showAddDialog")} breakpoints={{ "960px": "75vw", "640px": "100vw" }} style={{ width: "70vw" }}>
                    <AddCorporateCodeDialog editable={editable} permissions={permissions} editCode={editCode} onHide={() => onHide("showAddDialog")} />
                </Dialog>

                <div className="grid">
                    <div className="col-12 md:col-5">
                        <CustomPageHeader title={"Corporates"} />
                    </div>
                    <div className="col-12 md:col-7 text-right">
                        <span className="p-input-icon-right mr-3">
                            <InputText className="mbl_mrb" value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" />
                        </span>
                        <Button
                            onClick={() => {
                                setEditCode(null);
                                onClick("showAddDialog");
                            }}
                            label="Add"
                            className="p-button-success ml-2 p-button-rounded p-button-sm custom___width"
                        />
                    </div>
                </div>
                <DataTable loading={loading} responsiveLayout="scroll" value={list} globalFilterFields={["name", "code", "address", "fee"]} emptyMessage="No record found.">
                    <Column field="title" header="Name" />
                    <Column field="code" header="Code" />
                    <Column bodyClassName="text-center" header="Fee/Discount" body={feeBodyTemplate} />
                    <Column header="Status" body={statusBodyTemplate} />
                    <Column body={actionTemplate} header="Action" />
                </DataTable>
                <Paginator first={first} rows={rows} totalRecords={count} onPageChange={onPageChange} rowsPerPageOptions={[10, 20, 30, 50]} />
            </div>
        </>
    );
}
