import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addCorporateCode, editCorporateCode, getCorporateCodesList, resetAddEditCorporateCode, getGoogleAddress } from "../../../../../redux/slices/corporate_code_slice";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomInputField from "../../../components/custom_input_filed";
import { Button } from "primereact/button";
import { toast } from "react-toastify";

import { ReactSearchAutocomplete } from "react-search-autocomplete";
import Axios from "axios";
export default function AddCorporateCodeDialog({ onHide, editCode, permissions, editable }) {
    //redux
    const corporateCodeReducer = useSelector((state) => state.corporateCode);
    const modifiedLocations = corporateCodeReducer?.address?.map((location) => ({ title: location }));
    const { addLoading, addError, editLoading, editError, addSuccess, editSuccess } = corporateCodeReducer;
    const [selectedAddress, setSelectedAddress] = useState("");

    const userInfo = localStorage.getItem("user");
    const parsedData = JSON.parse(userInfo);
    const corporateId = parsedData?.corporate?._id;

    const dispatch = useDispatch();
    const statusList = [
        {
            code: true,
            name: "Active",
        },
        {
            code: false,
            name: "Inactive",
        },
    ];
    const validationSchema = Yup.object().shape({
        title: Yup.string().required("This field is required."),
        code: Yup.string().required("This field is required.").min(11, "Please enter valid code xxxx-xxxxxx").max(11, "Please enter valid code xxxx-xxxxxx"),
        fee: Yup.number().typeError("Please enter a valid number").min(-100, "Number must be greater than or equal to -100").max(100, "Number must be less than or equal to 100").required("This field is required."),
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            title: "",
            code: "",
            fee: "",
            corporateId: corporateId,
        },

        onSubmit: async (data) => {
            if (editCode == null) {
                const obj = {
                    code: data.code,
                    fee: data.fee,
                    title: data.title,
                    corporateId: data?.corporateId,
                };
                const response = await dispatch(addCorporateCode(obj));
                if (response) {
                    window.location.reload();
                }
            } else {
                const updateObj = {
                    code: data?.code,
                    fee: data?.fee,
                    title: data?.title,
                    corporateId: data?.corporateId,
                    active: data?.active.toString(),
                };
                const id = editCode?._id;
                const response = await dispatch(editCorporateCode({ id, body: updateObj }));
                if (response) {
                    window.location.reload();
                }
            }
        },
    });

    useEffect(() => {
        if (addSuccess !== undefined) {
            if (addSuccess === true) {
                toast.success("Corporate Code successfully added");
                onHide();
                dispatch(getCorporateCodesList());
            } else {
                toast.error(addError);
            }
        }
        if (editSuccess !== undefined) {
            if (editSuccess === true) {
                toast.success("Corporate Code successfully updated");
                onHide();
                dispatch(getCorporateCodesList());
            } else {
                toast.error(editError);
            }
        }
        return () => {
            dispatch(resetAddEditCorporateCode());
        };
    }, [addSuccess, addError, editSuccess, editError]);

    useEffect(() => {
        if (editCode !== null) {
            loadInitialValues();
        }
    }, []);

    const loadInitialValues = () => {
        formik.setFieldValue("title", editCode.title);
        formik.setFieldValue("code", editCode.code);
        formik.setFieldValue("fee", editCode.fee);
        formik.setFieldValue("active", editCode["active"]);
    };
    const [searchText, setSearchText] = useState("");

    // const handleInputChange = (event) => {
    //     const text = event;

    //     setSelectedAddress(event);
    //     setSearchText(text);

    //     dispatch(getGoogleAddress({ text }));
    // };
    // const handleOnSelect = (e) => {
    //     console.log(e, "yes");
    //     setSelectedAddress(e.title);
    // };

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="grid">
                <div className="p-fluid col-12 md:col-6">
                    <CustomInputField iden="title" formik={formik} type="text" disabled={editCode !== null} />
                </div>
                <div className="p-fluid col-12 md:col-6">{editCode !== null ? <CustomInputField iden="code" formik={formik} placeHolder="xxxx-xxxxxx" type="text" disabled={true} /> : <CustomInputField iden="code" formik={formik} mask="****-******" placeHolder="xxxx-xxxxxx" type="text" />} </div>
                <div className="p-fluid col-12 md:col-6">
                    <CustomInputField iden="fee" formik={formik} type="number" />
                </div>
                {editCode != null && (
                    <div className="p-fluid col-12 md:col-6">
                        <div className="field">
                            <CustomInputField iden={"active"} formik={formik} type="dropdown" optionLabel="name" optionValue="code" options={statusList} />
                        </div>
                    </div>
                )}
                <div className="p-fluid col-12">
                    <div className="button_class-modal">{permissions?.edit && editCode !== null && <Button loading={editCode == null ? addLoading : editLoading} type="submit" label={editCode === null ? "Add" : "Update"} className="p-button-success btn__default_modal p-button-rounded" />}</div>
                    <div className="button_class-modal">{permissions?.add && !editCode && <Button loading={editCode == null ? addLoading : editLoading} type="submit" label={editCode === null ? "Add" : "Update"} className="p-button-success btn__default_modal p-button-rounded" />}</div>
                </div>
                {/* <div className="p-fluid col-12"> */}
                {/* <div className="button_class-modal">{<Button loading={editCode == null ? addLoading : editLoading} type="submit" label={editCode === null ? "Add" : "Update"} className="p-button-success btn__default_modal p-button-rounded" />}</div> */}
                {/* <div className="button_class-modal">{<Button loading={editCode == null ? addLoading : editLoading} type="submit" label={editCode === null ? "Add" : "Update"} className="p-button-success btn__default_modal p-button-rounded" />}</div> */}
                {/* </div> */}
            </div>
        </form>
    );
}
