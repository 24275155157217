import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useDispatch, useSelector } from "react-redux";
import AddEditAdminUserDialog from "../components/add_edit_admin_user_dialog";
import { InputText } from "primereact/inputtext";
import { getAdminUsers, getUserRoles } from "../../../../../redux/slices/admin_user_slice";
import { Paginator } from "primereact/paginator";
import moment from "moment";
import { Dropdown } from "primereact/dropdown";

export default function AdminUsersScreen(props) {
    const permissions = props?.permissions;
    const [search, setSearch] = useState("");
    const [pageNumber, setPageNumber] = useState("");
    const [selectedRole, setSelectedRole] = useState("");
    const [selectedStatus, setSelectedStatus] = useState("");
    const adminUsersSelector = useSelector((state) => state.getUser);
    const { roles = [], adminUsers, loading, count, editSuccess, addSuccess } = adminUsersSelector;
    const dispatch = useDispatch();
    const [showAddUserDialog, setAddUserDialog] = useState(false);
    const [editUser, setEditUser] = useState(null);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(50);

    useEffect(() => {
        dispatch(getUserRoles());
    }, []);
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        setSearch(value);
    };


    const userInfo = localStorage.getItem('user')
    const parsedData = JSON.parse(userInfo)
    const corporateId = parsedData?.corporate?._id
    const dialogFuncMap = {
        showAddUserDialog: setAddUserDialog,
    };
    const onClick = (name) => {
        dialogFuncMap[`${name}`](true);
    };

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    };

    const statusBodyTemplate = (rowData) => {
        return <div className={rowData.status ? "bg_green" : "bg_red"}>{rowData.status ? "Active" : "Inactive"}</div>;
    };
    const actionsBodyTemplate = (rowData) => {
        return (
            <div>
                {permissions?.edit && (
                    <Button
                        icon="pi pi-user-edit"
                        className="p-button-rounded p-button-success"
                        onClick={() => {
                            setEditUser(rowData);
                            onClick("showAddUserDialog");
                        }}
                        aria-label="Edit"
                    />
                )}
            </div>
        );
    };
    useEffect(() => {
        if (!search) {
            setPageNumber(0);
        }
        dispatch(getAdminUsers({ pageNumber: 0, search, perPageRecords: rows, role: selectedRole, status: selectedStatus , corporate:corporateId }));
    }, [search]);

    useEffect(() => {
        dispatch(getAdminUsers({ pageNumber, search, perPageRecords: rows, role: selectedRole, status: selectedStatus , corporate:corporateId }));
    }, [editSuccess, addSuccess, selectedRole, selectedStatus]);
    const createdAtTemplate = (rowData) => {
        return <>{moment(rowData?.createdAt).format("MMMM Do YYYY, h:mm a")}</>;
    };

    const updatedAtTemplate = (rowData) => {
        return <>{moment(rowData?.updatedAt).format("MMMM Do YYYY, h:mm a")}</>;
    };
    const onPageChange = (event) => {
        setPageNumber(event.first / event.rows);
        setFirst(event.first);
        setRows(event.rows);
        dispatch(getAdminUsers({ pageNumber: event.first / event.rows, search, perPageRecords: event.rows, role: selectedRole, status: selectedStatus , corporate:corporateId }));
    };

    return (
        <>
            <Dialog header={editUser == null ? "Add User" : "Update User"} visible={showAddUserDialog} onHide={() => onHide("showAddUserDialog")} breakpoints={{ "960px": "75vw", "640px": "100vw" }} style={{ width: "70vw" }} className="Modal_class">
                <AddEditAdminUserDialog editUser={editUser} onHide={() => onHide("showAddUserDialog")} />
            </Dialog>
            <div className="card">
                <div className="grid pb-3">
                    <div className="col-12 md:col-2">
                        <h4>Admin Users</h4>
                    </div>
                    <div className="col-12 md:col-10">
                        <div className="mb-9 text-right">
                            <Dropdown
                                style={{ width: "215px" }}
                                className="mr-3 text-left"
                                options={[ ...roles]}
                                optionLabel="role"
                                optionValue="_id"
                                value={selectedRole}
                                onChange={(e) => {
                                    setSelectedRole(e.value);
                                }}
                                placeholder="Select Role"
                            />
                            <Dropdown
                                style={{ width: "215px" }}
                                className="mr-3 text-left"
                                options={[
                                    {
                                        text: "Active",
                                        value: 1,
                                    },
                                    {
                                        text: "Inactive",
                                        value: 0,
                                    },
                                ]}
                                optionLabel="text"
                                optionValue="value"
                                value={selectedStatus}
                                onChange={(e) => {
                                    setSelectedStatus(e.value);
                                }}
                                placeholder="Select Status"
                            />
                            <span className="p-input-icon-right mr-3">
                                <InputText className="mbl_view" value={search} onChange={onGlobalFilterChange} placeholder="Search" />
                            </span>
                            {permissions?.add && (
                                <Button
                                    label="Add new"
                                    className="p-button-success p-button-rounded p-button-sm custom___width"
                                    onClick={() => {
                                        setEditUser(null);
                                        onClick("showAddUserDialog");
                                    }}
                                />
                            )}

                            {/* <CustomInputField iden='type' placeholder="Select a Vehicle Type " formik={formik} type='dropdown' options={vehicleTypeList} optionLabel='type' optionValue='_id' value={vehicleType} onChange={(e) => setVehicleType(e.value)}         />
                        <CustomInputField iden='make' placeholder="Select Vehicle Makers"  formik={formik} type='dropdown' options={vehicleMakersList} optionLabel='make' optionValue='_id' value={vehicleMakers}  onChange={(e) => setVehicleMakers(e.value)} /> */}
                        </div>
                    </div>
                </div>

                <DataTable value={adminUsers} className="p-datatable-gridlines" showGridlines rows={50} dataKey="id" filterDisplay="menu" loading={loading} responsiveLayout="scroll" emptyMessage="No record found." globalFilterFields={["userName", "email", "mobile", "role.role"]}>
                    <Column field="userName" header="Username" style={{ minWidth: "5rem", maxWidth: "100%", overflowX: "auto" }} />
                    <Column field="email" header="Email" style={{ minWidth: "5rem", maxWidth: "100%", overflowX: "auto" }} />
                    <Column field="mobile" header="Mobile" style={{ minWidth: "10rem" }} />
                    <Column field="role.role" header="Role" style={{ minWidth: "10rem" }} />
                    <Column body={createdAtTemplate} header="Created At" style={{ minWidth: "10rem" }} />
                    <Column body={updatedAtTemplate} header="Updated At" style={{ minWidth: "10rem" }} />
                    <Column field="status" header="Status" style={{ minWidth: "4rem" }} body={statusBodyTemplate} />
                    <Column className="text-center Action_col" header="Actions" style={{ minWidth: "4rem" }} body={actionsBodyTemplate} />
                </DataTable>
                <Paginator first={first} rows={rows} totalRecords={count} onPageChange={onPageChange} rowsPerPageOptions={[10, 20, 30, 50]} />
            </div>
        </>
    );
}
