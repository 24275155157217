import "react-app-polyfill/ie11";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
//import * as serviceWorker from './serviceWorker';
import { HashRouter } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import store from "./store";
import { Provider } from "react-redux";
import appUrl from "./app/features/constants/appUrl";
import { ContextProvider } from "./Context";
// import { toast } from 'react-toast';
// import SocketProvider from "./SocketProvider";

// //console.log(appUrl.baseUrl+'?name=AIS');

ReactDOM.render(
    <HashRouter>
        <ScrollToTop>
            <Provider store={store}>
                <ContextProvider socketUrl={appUrl.socketBaseUrl}>
                    <App />
                </ContextProvider>
            </Provider>
        </ScrollToTop>
    </HashRouter>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();
