import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar } from "primereact/avatar";
import { Dialog } from "primereact/dialog";
import EditUserDialog from "../../users/components/edit_passenger_dialog";
import DocumentsDialog from "../components/documentsDialog";
import { useHistory } from "react-router-dom";
import { Image } from "primereact/image";
import appUrl from "../../../constants/appUrl";
import UserProfileDialog from "../../users/components/user_profile_dialog";
import { InputText } from "primereact/inputtext";
import VerificationDialog from "../components/licence_verification_dialog";
import { Paginator } from "primereact/paginator";
import { getDriversList } from "../../../../../redux/slices/drivers_slice";
import moment from "moment";
import { Dropdown } from "primereact/dropdown";
import male from '../../../../../assets/img/male.png'
import female from '../../../../../assets/img/female.png'
import passenger from '../../../../../assets/img/passenger.png'
import driver from '../../../../../assets/img/driver.jpg'
import { axiosApi } from "../../../constants/axios_api";
import Axios from "axios";
import { toast } from "react-toastify";

export default function DriversScreen(props) {
    const permissions = props?.permissions;
    //redux
    const driversListReducer = useSelector((state) => state.driversList);
    const { drivers, loading, count = 0, updateSuccess } = driversListReducer;
    const dispatch = useDispatch();

    //hooks
    const [selectedCategory] = useState("all");
    const [showEditDialog, setEditDialog] = useState(false);
    const [showProfileDialog, setProfileDialog] = useState(false);
    const [showDocumentsDialog, setDocumentsDialog] = useState(false);
    const [showLicenceDialog, setLicenceDialog] = useState(false);
    const [selectedGender, setSelectedGender] = useState("");
    const [selectedLoginDevice, setSelectedLoginDevice] = useState("");
    const [selectedStatus, setSelectedStatus] = useState("");

    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(50);
    const [pageNumber, setPageNumber] = useState(0);
    const [defaultImage, setDefaultImage] = useState("app/black-watch.jpg");
    const [editUser, setEditUser] = useState(null);
    const history = useHistory();
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [driversList, setDriversList] = useState()
    

    useEffect(() => {
        setDriversList(drivers)
    }, [drivers])


    const userInfo = localStorage.getItem('user')
    const parsedData = JSON.parse(userInfo)
    const corporateId = parsedData?.corporate?._id

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;

        setGlobalFilterValue(value);
    };

    // dialog
    const dialogFuncMap = {
        showEditDialog: setEditDialog,
        showProfileDialog: setProfileDialog,
        showDocumentsDialog: setDocumentsDialog,
        showLicenceDialog: setLicenceDialog,
    };
    const onClick = (name) => {
        dialogFuncMap[`${name}`](true);
    };
    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    };

    //coponents
    const userTypeTemp = (rowData) => {
        return <div className="">{rowData.userType === "3" ? "Both" : rowData.userType === "2" ? "Passenger" : "Owners"}</div>;
    };

    const activeStatusBody = (rowData) => {
        return <div className={rowData.active.status ? "bg_green" : "bg_red"}>{rowData.active.status ? "Active" : "Inactive"}</div>;
    };

    const handleDelete = async(rowData) => {
        const response = await axiosApi.put(`${appUrl?.baseUrl}api/corporate/blacklist?id=${rowData?._id}`)
        console.log('response', response)
        if (response?.status === 200) {
            toast.success('Driver blacklisted successfully')
             dispatch(getDriversList({ status: selectedCategory, pageNumber: 0, perPageRecords: rows, gender: selectedGender, loginDevice: selectedLoginDevice, status: selectedStatus, corporate:corporateId }));
        } else {
            toast.error('Something went wrong')
        }
    }
    const actionTemplate = (rowData) => {
       return <Button className="pi pi-trash p-button-rounded pr-3" tooltip="Blacklist driver" iconPos="center" onClick={()=>handleDelete(rowData)}/>
        // return (
        //     <div className="Edit_Icon">
        //         <>
        //             {permissions?.view && (
        //                 <Button
        //                     tooltip={rowData?.totalRatingCount ? (rowData?.totalRatingCount / rowData?.totalRatingCount).toFixed(1).toString() : "0.0"}
        //                     onClick={() => {
        //                         history.push(`/api/user/drivers/ratings/list/${rowData?._id}/1`);
        //                     }}
        //                     icon="pi pi-star-fill"
        //                     // badge={ratingFixed}
        //                     // badgeClassName="p-badge-danger"
        //                     tooltipOptions={{ position: "top" }}
        //                     className="p-button-warning m-2 p-button-rounded"
        //                 />
        //             )}
        //             {permissions?.view && (
        //                 <Button
        //                     tooltip="View Vehicles List"
        //                     icon="pi pi-car"
        //                     tooltipOptions={{ position: "top" }}
        //                     className="edit m-2 p-button-rounded"
        //                     onClick={() => {
        //                         history.push(`/api/user/drivers/vehicles/list/${rowData?._id}/1`);
        //                     }}
        //                 />
        //             )}
        //             {permissions?.edit && (
        //                 <Button
        //                     tooltip="Status"
        //                     icon="pi pi-pencil"
        //                     tooltipOptions={{ position: "top" }}
        //                     className="edit m-2 p-button-rounded"
        //                     onClick={() => {
        //                         setEditUser(rowData);
        //                         onClick("showEditDialog");
        //                     }}
        //                 />
        //             )}
        //             {permissions?.view && (
        //                 <Button
        //                     tooltip="Document Verification"
        //                     icon="pi pi-image"
        //                     tooltipOptions={{ position: "top" }}
        //                     className="edit m-2 p-button-rounded"
        //                     onClick={() => {
        //                         setEditUser(rowData);
        //                         onClick("showDocumentsDialog");
        //                     }}
        //                 />
        //             )}
        //             {/* <Button
        //             tooltip="Vehicles"
        //             icon="pi pi-car"
        //             tooltipOptions={{ position: "top" }}
        //             className="edit m-2 p-button-rounded"
        //             onClick={() => {
        //                 setEditUser(rowData);
        //                 onClick("showUserVehicleDialog");
        //                 // history.push(`/api/user/drivers/vehicles/list/${rowData._id}`);
        //             }}
        //         /> */}

        //             {permissions?.view && (
        //                 <Button
        //                     tooltip="Rides"
        //                     icon="pi pi-car"
        //                     tooltipOptions={{ position: "top" }}
        //                     className="p-button-success m-2 p-button-rounded"
        //                     onClick={() => {
        //                         history.push(`/api/user/drivers/rides/list/${rowData?._id}/1`);
        //                     }}
        //                 />
        //             )}

        //             {permissions?.view && (
        //                 <Avatar
        //                     icon="pi pi-user"
        //                     className="m-2 p-button-rounded"
        //                     size="large"
        //                     shape="circle"
        //                     tooltipOptions={{ position: "top" }}
        //                     tooltip="View Profile"
        //                     onClick={() => {
        //                         setEditUser(rowData);
        //                         onClick("showProfileDialog");
        //                     }}
        //                 />
        //             )}
        //         </>
        //     </div>
        // );
    };

    const firstNameTemp = (rowData) => {
        const name = rowData.firstName;

        return <div>{name.charAt(0).toUpperCase() + name.slice(1)}</div>;
    };
    const lastNameTemp = (rowData) => {
        const name = rowData.lastName;
        return <div>{name.charAt(0).toUpperCase() + name.slice(1)}</div>;
    };
    const signupAsTemp = (rowData) => {
        if (rowData.signupAs == 1) {
            return <img src={driver} title="Driver" style={{height:'50px' , width:'50px'}}/>;
        } else if (rowData.signupAs == 2) {
            return <img src={passenger} title="Passenger" style={{height:'40px' , width:'40px'}}/>;
        } else {
            return "";
        }
    };
    const createdAtTemplate = (rowData) => {
        return <>{moment(rowData?.createdAt).format("MMMM Do YYYY, h:mm a")}</>;
    };

    const updatedAtTemplate = (rowData) => {
        return <>{moment(rowData?.updatedAt).format("MMMM Do YYYY, h:mm a")}</>;
    };
    useEffect(() => {
        dispatch(getDriversList({ status: selectedCategory, pageNumber: 0, perPageRecords: rows, gender: selectedGender, loginDevice: selectedLoginDevice, status: selectedStatus, corporate:corporateId }));
    }, [selectedCategory, dispatch]);
    useEffect(() => {
        if (updateSuccess) dispatch(getDriversList({ status: selectedCategory, pageNumber: 0, perPageRecords: rows, gender: selectedGender, loginDevice: selectedLoginDevice, status: selectedStatus , corporate: corporateId }));
    }, [updateSuccess]);
    useEffect(() => {
        if (globalFilterValue) {
            setPageNumber(0);
            setFirst(0);
        }
        dispatch(getDriversList({ status: selectedCategory, pageNumber, text: globalFilterValue, perPageRecords: rows, gender: selectedGender, loginDevice: selectedLoginDevice, status: selectedStatus , corporate: corporateId}));
    }, [globalFilterValue, selectedGender, selectedLoginDevice, selectedStatus]);

    const onPageChange = (event) => {
        setRows(event.rows);
        setFirst(event.first);
        setPageNumber(event.first / rows);
        dispatch(getDriversList({ status: selectedCategory, pageNumber: event.first / rows, text: globalFilterValue, perPageRecords: event.rows, gender: selectedGender, loginDevice: selectedLoginDevice, status: selectedStatus , corporate: corporateId}));
    };

    const genderTemp = (rowData) => {
        const gender = rowData.gender;
        return <div>
            <img src={gender === 'female' ? female : male} alt="Gender logo" style={{ width: '30px', height: '30px' }} className="ml-4" title={gender === 'female'?'female' : 'male'}/>
        </div>;
    };

    return (
        <>
            <Dialog header="Update Status" visible={showEditDialog} onHide={() => onHide("showEditDialog")} breakpoints={{ "960px": "75vw", "640px": "90vw" }} style={{ width: "30vw" }}>
                <EditUserDialog permissions={permissions} editUser={editUser} onHide={() => onHide("showEditDialog")} />
            </Dialog>

            <Dialog header="Document verifications" visible={showDocumentsDialog} onHide={() => onHide("showDocumentsDialog")} breakpoints={{ "960px": "75vw", "640px": "90vw" }} style={{ width: "70vw" }}>
                <div>{editUser != null && <DocumentsDialog permissions={permissions} onHide={() => onHide("showDocumentsDialog")} userId={editUser._id} user={editUser} updateSuccess={updateSuccess} />}</div>
            </Dialog>

            <Dialog header="Profile" position="center" className="text-center" visible={showProfileDialog} style={{ width: "60vw", height: "" }} onHide={() => onHide("showProfileDialog")} breakpoints={{ "960px": "75vw", "640px": "90vw" }}>
                <div>{editUser != null && <UserProfileDialog permissions={permissions} userId={editUser._id} editUserData={editUser} userType="1" />}</div>
            </Dialog>

            <Dialog header="License Verification" visible={showLicenceDialog} onHide={() => onHide("showLicenceDialog")} breakpoints={{ "960px": "75vw", "640px": "90vw" }} style={{ width: "70vw" }}>
                <VerificationDialog permissions={permissions} editUser={editUser} onHide={() => onHide("showLicenceDialog")} />
            </Dialog>
            <div className="card">
                <div className="grid pb-3">
                    <div className="col-12 md:col-2">
                        <h4>Owners</h4>
                    </div>
                    <div className="col-12 md:col-10">
                        <div className="mb-9 text-right">
                            <Dropdown
                                className="ml-3"
                                value={selectedGender}
                                options={[
                                    {
                                        text: "Male",
                                        value: "male",
                                    },
                                    {
                                        text: "Female",
                                        value: "female",
                                    },
                                ]}
                                optionLabel="text"
                                optionValue="value"
                                onChange={(e) => {
                                    setSelectedGender(e.value);
                                }}
                                placeholder="Select Gender"
                            />
                            <Dropdown
                                className="ml-3"
                                value={selectedStatus}
                                options={[

                                    {
                                        text: "Active",
                                        value: 1,
                                    },
                                    {
                                        text: "Inactive",
                                        value: 0,
                                    },
                                ]}
                                optionLabel="text"
                                optionValue="value"
                                onChange={(e) => {
                                    setSelectedStatus(e.value);
                                }}
                                placeholder="Select Status"
                            />
                            <Dropdown
                                className="mx-3"
                                value={selectedLoginDevice}
                                options={[

                                    {
                                        text: "IOS",
                                        value: "ios",
                                    },
                                    {
                                        text: "Android",
                                        value: "android",
                                    },
                                ]}
                                optionLabel="text"
                                optionValue="value"
                                onChange={(e) => {
                                    setSelectedLoginDevice(e.value);
                                }}
                                placeholder="Select Login Device"
                            />

                            <span className="p-input-icon-right mr-3">
                                <InputText className="mb-3" value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" />
                            </span>
                            {/* <CustomInputField iden='type' placeholder="Select a Vehicle Type " formik={formik} type='dropdown' options={vehicleTypeList} optionLabel='type' optionValue='_id' value={vehicleType} onChange={(e) => setVehicleType(e.value)}         />
                        <CustomInputField iden='make' placeholder="Select Vehicle Makers"  formik={formik} type='dropdown' options={vehicleMakersList} optionLabel='make' optionValue='_id' value={vehicleMakers}  onChange={(e) => setVehicleMakers(e.value)} /> */}
                        </div>
                    </div>
                </div>

                <DataTable loading={loading} responsiveLayout="scroll" value={driversList} key="_id" globalFilterFields={["firstName", "lastName", "cnic", "mobile"]} emptyMessage="No record found.">
                    <Column
                        header="Image"
                        body={(data) => {
                            return (
                                <div>
                                    <Image imageStyle={{ borderRadius: "50%", width: "30px", height: "30px" }} src={data.profileImage ? `${appUrl.fileBaseUrl}${data?.profileImage}` : defaultImage} onError={() => setDefaultImage("app/black-watch.jpg")} />
                                </div>
                            );
                        }}
                    />
                    <Column style={{ maxWidth: "5rem", maxWidth: "100%", overflowX: "auto" }} body={firstNameTemp} header="First Name" />
                    <Column style={{ maxWidth: "5rem", maxWidth: "100%", overflowX: "auto" }} body={lastNameTemp} header="Last Name" />
                    <Column style={{ minWidth: "" }} field="cnic" header="CNIC" />
                    <Column style={{ minWidth: "" }} field="mobile" header="Mobile" />
                    <Column style={{ minWidth: "" }} body={userTypeTemp} header="Type" />
                    <Column style={{ minWidth: "" }} body={(rowData)=>genderTemp(rowData)} header="Gender" />
                    <Column style={{ minWidth: "" }} body={signupAsTemp} header="Signup As" />
                    <Column
                        bodyClassName="text-center"
                        style={{ minWidth: "" }}
                        header="Log In Device"
                        body={(rowData) => {
                            if (rowData.loginDevice == "android") return <img height="25" width="25" src="images/android.png" title={rowData.loginDevice == "android" ? 'Android' : 'IOS'} />;
                            else if (rowData.loginDevice == "ios") return <img height="25" width="25" src="images/apple.png" title={rowData.loginDevice == "android" ? 'Android' : 'IOS'}/>;
                            else return rowData.loginDevice;
                        }}
                    />
                    <Column
                        bodyClassName="text-center"
                        style={{ minWidth: "" }}
                        header="Signup Device"
                        body={(rowData) => {
                            if (rowData.signupDevice == "android") return <img height="25" width="25" src="images/android.png" title={rowData.signupDevice == "android" ? 'Android' : 'IOS'}/>;
                            else if (rowData.signupDevice == "ios") return <img height="25" width="25" src="images/apple.png" title={rowData.signupDevice == "android" ? 'Android' : 'IOS'}/>;
                            else return rowData.signupDevice;
                        }}
                    />
                    <Column style={{ minWidth: "8rem" }} body={createdAtTemplate} header="Created At" />
                    <Column style={{ minWidth: "8rem" }} body={updatedAtTemplate} header="Updated At" />
                    <Column body={activeStatusBody} header="Status" />
                    <Column header="Action" body={actionTemplate} />
                </DataTable>
                <Paginator first={first} rows={rows} totalRecords={count} onPageChange={onPageChange} rowsPerPageOptions={[10, 20, 30, 50]} />
            </div>
        </>
    );
}
